import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'
import { APP_VERSION, DEPLOY_TYPE } from 'shared/envs'

import { SWH_ROUTES_CATALOG, SWH_ROUTES_INVOICE, SWH_ROUTES_INVOICE_SETTINGS, SWH_ROUTES_SALES } from './swh.routes'

export enum SwhFlag {
  ALL = 'conta-stone-swh',
  CATALOG = 'conta-stone-swh-catalog'
}

type FlagPermission = {
  routes: string[]
}

const flagPermissions: Record<SwhFlag, FlagPermission> = {
  [SwhFlag.ALL]: {
    routes: [...SWH_ROUTES_CATALOG, ...SWH_ROUTES_INVOICE, ...SWH_ROUTES_INVOICE_SETTINGS, ...SWH_ROUTES_SALES]
  },
  [SwhFlag.CATALOG]: {
    routes: SWH_ROUTES_CATALOG
  }
}

export class SwhExperimentFlags {
  private swhFlags: Set<SwhFlag> = new Set()
  private readonly activeVariantValue = 'on'

  constructor(experimentData: ExperimentValues | undefined) {
    Object.values(SwhFlag).forEach(flag => {
      if (experimentData?.[flag]?.value === this.activeVariantValue) {
        this.swhFlags.add(flag)
      }
    })
  }

  checkActiveFlagByPathname(pathname: string): boolean {
    const pathNameCurrent = DEPLOY_TYPE() === 'preview' ? pathname.replace(`/${APP_VERSION()}`, '') : pathname
    for (const key in flagPermissions) {
      if (!this.swhFlags.has(key as SwhFlag)) continue

      if (flagPermissions?.[key as SwhFlag]?.routes.includes(pathNameCurrent)) {
        return true
      }
    }
    return false
  }
}
