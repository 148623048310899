import { SWH_ROUTES } from 'swh/shared/utils'

import { ACQUIRER_ROUTES } from '~/domains/acquirer/ports/acquirer.routes'
import { BANKING_ROUTES } from '~/domains/banking/ports/banking.routes'
import { CREDIT_ROUTES } from '~/domains/credit/ports/credit.routes'
import { INVESTMENT_ROUTES } from '~/domains/investment/ports/investment.routes'
import { MISCELLANEOUS_ROUTES } from '~/domains/miscellaneous/ports/miscellaneous.routes'
import { RISK_ROUTES } from '~/domains/risk/ports/risk.routes'
import { SMB_CONVERSION_ROUTES } from '~/domains/smb/conversion/ports/smb-conversion.routes'
import { STONE_ACCOUNT_ROUTES } from '~/domains/stone-account/ports/stone-account.routes'
import { SVA_ROUTES } from '~/domains/sva/ports/sva.routes'

import { blackBirdFactory } from './blackbird'

const GENERIC_ROUTES = ['/', '/home', '/404'] as const
const PUBLIC_ROUTES = ['/termos-uso-cartao-credito', '/autocred', '/conta-pj'] as const

export const routes = [
  ...GENERIC_ROUTES,
  ...BANKING_ROUTES,
  ...STONE_ACCOUNT_ROUTES,
  ...RISK_ROUTES,
  ...ACQUIRER_ROUTES,
  ...MISCELLANEOUS_ROUTES,
  ...SVA_ROUTES,
  ...CREDIT_ROUTES,
  ...INVESTMENT_ROUTES,
  ...SMB_CONVERSION_ROUTES,
  ...PUBLIC_ROUTES,
  ...SWH_ROUTES
]

export type Routes = (typeof routes)[number]

const BlackBird = blackBirdFactory<Routes>(routes)

export { blackBirdFactory }

export default BlackBird
