export const SWH_URL_CATALOG = '/catalogo/produtos'
export const SWH_URL_SALES = '/swh/vendas'
export const SWH_URL_INVOICES = '/swh/notas-fiscais'
export const SWH_URL_INVOICE_SETTINGS = '/swh/configuracoes/nota-fiscal'

export const SWH_ROUTES: string[] = [
  SWH_URL_INVOICE_SETTINGS,
  SWH_URL_INVOICES,
  SWH_URL_CATALOG,
  SWH_URL_SALES,
  '/catalogo/novo-produto',
  '/catalogo/produto/${id}',
  '/swh/vendas/${id}'
] as const

export const SWH_ROUTES_SALES: string[] = ['/swh/sales', SWH_URL_SALES]

export const SWH_ROUTES_CATALOG: string[] = ['/swh/products', SWH_URL_CATALOG]

export const SWH_ROUTES_INVOICE: string[] = ['/swh/invoices', SWH_URL_INVOICES]

export const SWH_ROUTES_INVOICE_SETTINGS: string[] = ['/swh/settings/invoice', SWH_URL_INVOICE_SETTINGS]
