import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'
import {
  SWH_ROUTES_CATALOG,
  SWH_ROUTES_INVOICE,
  SWH_ROUTES_SALES,
  SWH_URL_CATALOG,
  SWH_URL_INVOICES,
  SWH_URL_SALES,
  SwhExperimentFlags
} from 'swh/shared/utils'

import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'

export async function swhMenu(entity: Entity<'acquirer'>, expData: ExperimentValues | undefined) {
  if (expData === undefined || entity === undefined) return []

  const swhExperimentFlags = new SwhExperimentFlags(expData)

  return [
    {
      order: 7,
      label: 'Vendas',
      icon: 'shop-outline',
      show: swhExperimentFlags.checkActiveFlagByPathname(SWH_URL_SALES),
      onClick: () => {},
      path: '/swh/vendas',
      active: (route: string) => SWH_ROUTES_SALES.includes(route)
    },
    {
      order: 8,
      label: 'Produtos',
      icon: 'tag-outline',
      show: swhExperimentFlags.checkActiveFlagByPathname(SWH_URL_CATALOG),
      onClick: () => {},
      path: SWH_URL_CATALOG,
      active: (route: string) => SWH_ROUTES_CATALOG.includes(route),
      isNew: true
    },
    {
      order: 8,
      label: 'Notas fiscais',
      icon: 'file-unfolded-outline',
      show: swhExperimentFlags.checkActiveFlagByPathname(SWH_URL_INVOICES),
      onClick: () => {},
      path: SWH_URL_INVOICES,
      active: (route: string) => SWH_ROUTES_INVOICE.includes(route),
      isNew: true
    }
  ]
}
